import {
  SubmitResumeActions,
  SetInputValidated,
  SET_INPUT_SUBMIT_RESUME,
  SET_FORM_LOADING_SUBMIT_RESUME,
  SetFormLoading,
  SET_PHONE_INPUT_SUBMIT_RESUME,
  SetPhoneInputValidated,
  SET_FORM_SUCCESS_SUBMIT_RESUME,
  SetFormSuccess,
  SetCheckboxValidated,
  SET_CHECKBOX_SUBMIT_RESUME,
  SET_GENERAL_ERROR_SUBMIT_RESUME,
  SetGeneralErrorMessage,
  RESET_FORM_SUBMIT_RESUME,
} from './actions';

export type SubmitResumeData = {
  isLoading: boolean;
  isSuccess: boolean;
  generalError: {
    message?: string;
  };
  recaptcha: {
    value: string;
    error: string;
  };
  firstName: {
    value: string;
    error: string;
  };
  lastName: {
    value: string;
    error: string;
  };
  currentLocation: {
    value: string;
    error: string;
  };
  phoneNumber: {
    countryCode: string;
    number: string;
    error: string;
  };
  email: {
    value: string;
    error: string;
  };
  resume: {
    value?: File | undefined;
    error: string;
  };
  designation: {
    value: string;
    error: string;
  };
  yearsOfExperience: {
    value: string;
    error: string;
  };
  role: {
    value: string;
    error: string;
  };
  linkedinUrl: {
    value: string;
    error: string;
  };
  isPolicyChecked: {
    value: {[key: string]: boolean};
    error: string;
  };
}

export const initialState: SubmitResumeData = {
  isLoading: false,
  isSuccess: false,
  generalError: {
    message: '',
  },
  recaptcha: {
    value: '',
    error: '',
  },
  firstName: {
    value: '',
    error: '',
  },
  lastName: {
    value: '',
    error: '',
  },
  currentLocation: {
    value: '',
    error: '',
  },
  phoneNumber: {
    countryCode: '+971',
    number: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  resume: {
    value: undefined,
    error: '',
  },
  designation: {
    value: '',
    error: '',
  },
  yearsOfExperience: {
    value: '',
    error: '',
  },
  role: {
    value: '',
    error: '',
  },
  linkedinUrl: {
    value: '',
    error: '',
  },
  isPolicyChecked: {
    value: {},
    error: '',
  },
};

const submitYourResume = (
  state: SubmitResumeData = initialState,
  action: SubmitResumeActions,
): SubmitResumeData => {
  switch (action.type) {
    case SET_INPUT_SUBMIT_RESUME:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_PHONE_INPUT_SUBMIT_RESUME:
      return {
        ...state,
        ...(action as SetPhoneInputValidated).payload,
      };
    case SET_FORM_LOADING_SUBMIT_RESUME: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_SUBMIT_RESUME: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SET_CHECKBOX_SUBMIT_RESUME:
      return {
        ...state,
        ...(action as SetCheckboxValidated).payload,
      };
    case SET_GENERAL_ERROR_SUBMIT_RESUME: {
      return {
        ...state,
        generalError: {
          message: (action as SetGeneralErrorMessage).payload,
        },
      };
    }
    case RESET_FORM_SUBMIT_RESUME: {
      return initialState;
    }
    default:
      return state;
  }
};

export default submitYourResume;
